import './App.css';
import DealerReportingSearch from './components/DealerReportingSearch';
import {useAuth} from "react-oidc-context";

function App() {
    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <div className="App">
                <DealerReportingSearch/>
            </div>
        );
    }

    return auth.signinRedirect();
}

export default App;
